@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-left align-middle;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input{
  @apply border border-gray-300 rounded-lg p-2 text-center;
}
select{
  @apply border border-gray-300 rounded-lg p-2;
}

label , .label, div{
  @apply text-gray-700 text-left align-middle;
}

.value{
  @apply text-orange-600 font-bold border border-gray-300 rounded-lg p-2 text-center;
}

.value.final{
  @apply text-green-600 font-bold text-center;
}
.value.err{
  @apply text-red-600 font-bold text-center;
}

.section{
  @apply border border-gray-200 p-4 gap-y-5 shadow-md hover:shadow-lg;
}
